import React from 'react';
import Checkbox from './Checkbox';

class CheckboxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedItems: this.props.checkedItems || new Map(),
    }

    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => {
      const newState = prevState.checkedItems.set(item, isChecked)
      this.props.setItems(newState)
      return {
        checkedItems: newState
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        {
          this.props.items.map(item => (
            <span key={item.id} className="checkbox form-check">
              <label>
                <Checkbox name={`${item.id}`} checked={this.state.checkedItems.get(item.id.toString())}
                          onChange={this.handleChange}/>
                {` ${item.name}`}
              </label>
            </span>
          ))
        }
      </React.Fragment>
    );
  }
}

export default CheckboxContainer;
