import React, { useState, useEffect, useRef } from 'react';
import Video, { LocalVideoTrack } from 'twilio-video';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faVideo,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
  faExpandArrowsAlt,
  faCompressArrowsAlt
} from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import Participant from "./Participant";
import Timer from "./elements/Timer";
import ChatScreen from "../chat/ChatScreen";

const Room = ({ roomName, token, backUrl, updateUrl, userName, ownerUserName, roomId, chatSid }) => {
  const [room, setRoom] = useState(null);
  const [screenTrack, setScreenTrack] = useState(null);
  const [participants, setParticipants] = useState([]);

  const [fullscreenEnabled, setFullscreenEnabled] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [screenShareEnabled, setScreenShareEnabled] = useState(false);
  const [chatEnabled, setChatEnabled] = useState(false);
  const [timerEnabled, setTimerEnabled] = useState('');

  const [newChatMessages, updateNewChatMessages] = useState(0);

  const videoWindowRef = useRef();
  const screenVideoRef = useRef();

  const handleLogout = () => {
    fetch(`${updateUrl}.json`, {
      method: 'PATCH',
      body: JSON.stringify({
        meetings_room: {
          start_time: timerEnabled / 1000,
          duration: moment.utc(Date.now()).diff(timerEnabled, 'seconds')
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setRoom(currentRoom => {
      if (currentRoom && currentRoom.localParticipant.state === 'connected') {
        currentRoom.localParticipant.tracks.forEach(trackPublication => {
          trackPublication.track.stop();
        });
        currentRoom.disconnect();
        window.location.assign(backUrl)
      } else {
        return currentRoom;
      }
    });
  }

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);

      if (participant.identity == ownerUserName) {
        fetch(`/pokoje/${roomId}.json`)
          .then(response => response.json())
          .then(data => setTimerEnabled(data.start_time * 1000));
      }
    };
    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };

    token && Video.connect(token, {
      name: roomName
    }).then(room => {
      setRoom(room);
      if (room.localParticipant.identity == ownerUserName) {
        fetch(`/pokoje/${roomId}.json`)
          .then(response => response.json())
          .then(data => {
            if (data.start_time.length < 5) {
              setTimerEnabled(new Date().getTime())
              fetch(updateUrl, {
                method: 'PATCH',
                body: JSON.stringify({
                  meetings_room: {
                    start_time: Math.round(timerEnabled / 1000),
                    duration: 0
                  }
                }),
                headers: {
                  'Content-Type': 'application/json'
                }
              });
            } else {
              setTimerEnabled(data.start_time * 1000)
            }
          });
      }
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      handleLogout();
    };
  }, [roomName, token]);

  const toggleAudio = () => {
    if (audioEnabled) {
      setAudioEnabled(false)
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });
    } else {
      setAudioEnabled(true)
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.enable();
      });
    }
  }

  const toggleVideo = () => {
    if (videoEnabled) {
      setVideoEnabled(false)
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.disable();
      });
    } else {
      setVideoEnabled(true)
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.enable();
      });
    }
  }

  const handleFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement ||
      fullscreenEnabled
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setFullscreenEnabled(false)
      videoWindowRef.current.classList.remove("fullscreen");
    } else {
      setFullscreenEnabled(true)
      videoWindowRef.current.classList.add("fullscreen");
      if (videoWindowRef.current.requestFullscreen) {
        videoWindowRef.current.requestFullscreen();
      } else if (videoWindowRef.current.mozRequestFullScreen) {
        videoWindowRef.current.mozRequestFullScreen();
      } else if (videoWindowRef.current.webkitRequestFullscreen) {
        videoWindowRef.current.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (videoWindowRef.current.msRequestFullscreen) {
        videoWindowRef.current.msRequestFullscreen();
      }
    }
  }


  const handleScreenShare = async () => {
    if (screenShareEnabled) {
      setScreenShareEnabled(false)
      room.localParticipant.unpublishTrack(screenTrack);
      screenTrack.stop()
    } else {
      setScreenShareEnabled(true)
      const stream = await navigator.mediaDevices.getDisplayMedia();
      const localScreenTrack = new LocalVideoTrack(stream.getTracks()[0]);
      setScreenTrack(localScreenTrack)
      room.localParticipant.publishTrack(localScreenTrack);

      // const videoTrack = room.localParticipant.videoTracks[];
      localScreenTrack.attach(screenVideoRef.current);
    }
  }

  const toggleChat = () => {
    if (chatEnabled) {
      setChatEnabled(false)
    } else {
      updateNewChatMessages(0)
      setChatEnabled(true)
    }
  }

  const onNewChatMessageUpdate = () => {
    if (!chatEnabled) {
      updateNewChatMessages(amount => amount + 1)
    }
  }

  return (
    <div className="container margin_60_35 bg-white">
      {/*call window*/}

      <div ref={videoWindowRef} className={`call-window-2 mb-5 mt-3 ${fullscreenEnabled && 'fullscreen'}`}>
        <div className="call-body2">
          {/*Call Contents*/}
          <div className="call-contents">
            <div className="call-content-wrap">
              <div className="user-video">
                {
                  participants.map(participant => (
                    <Participant key={participant.sid} participant={participant}/>
                  ))
                }
              </div>

              {/*render 'websites/subpages/shared/info_button'*/}

              {
                room && (
                  <div className="my-video">
                    <ul>
                      <li>
                        <Participant
                          key={room.localParticipant.sid}
                          participant={room.localParticipant}
                        />
                      </li>
                      {
                        screenShareEnabled && (
                          <li>
                            <video ref={screenVideoRef}/>
                          </li>
                        )
                      }
                    </ul>
                  </div>
                )
              }
            </div>
          </div>
          {/*Call Contents*/}

          {/* Call Footer */}
          <div className="call-footer">
            <div className="call-icons">
              <div className="left">
                <div className={`show-chat ${chatEnabled && "active"}`}>
                  <a className={`chat-toggle ${chatEnabled && "active"}`} role="button" title=""
                     onClick={() => toggleChat()}>
                    <i className="fas fa-comments"></i>
                    {newChatMessages > 0 && <span style={{ fontSize: '40%', position: 'absolute' }}
                                                  className="badge badge-info">{newChatMessages}</span>}
                  </a>
                </div>
                <div className="video-duartion">
                  {timerEnabled && <Timer startDate={timerEnabled} updateUrl={updateUrl}/>}
                </div>
              </div>
              <ul className="call-items">
                <li className="call-item">
                  <a onClick={toggleVideo} data-placement="top" data-toggle="tooltip"
                     data-original-title="Udostepnij wideo">
                    <FontAwesomeIcon icon={videoEnabled ? faVideo : faVideoSlash}/>
                  </a>
                </li>
                <li className="call-item">
                  <a onClick={toggleAudio} data-placement="top" data-toggle="tooltip"
                     data-original-title="Wycisz">
                    <FontAwesomeIcon icon={audioEnabled ? faMicrophone : faMicrophoneSlash}/>
                  </a>
                </li>
                <li className="call-item">
                  <a className="call-fullscreen-toggle" role="button" title="" data-placement="top"
                     data-toggle="tooltip" data-original-title="Pełny ekran" onClick={handleFullscreen}>
                    <FontAwesomeIcon icon={fullscreenEnabled ? faCompressArrowsAlt : faExpandArrowsAlt}/>
                  </a>
                </li>
                <li className="call-item call-end">
                  <a onClick={handleLogout}
                     data-placement="top"
                     data-toggle="tooltip"
                     data-original-title="Zakończ rozmowe">
                    <i className="fas fa-phone"></i>
                  </a>
                </li>
              </ul>

              <ul className="nav float-right custom-menu settings-menu">
                <li className="nav-item dropup">
                  <a className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-cog"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a onClick={handleScreenShare} data-toggle="modal" data-target="#video-settings"
                       className="dropdown-item">{screenShareEnabled ? "Zatrzymaj udostępnianie ekranu" : "Udostępnij ekran"}</a>
                  </div>
                </li>
              </ul>

            </div>
          </div>
          {/* Call Footer */}
        </div>

        <ChatScreen
          chatEnabled={chatEnabled}
          toggleChat={toggleChat}
          userName={userName}
          roonName={roomName}
          chatSid={chatSid}
          onNewChatMessageUpdate={onNewChatMessageUpdate}
          setFullscreenEnabled={setFullscreenEnabled}
        />
      </div>
      {/*call window*/}
    </div>
  );
};

export default Room;
