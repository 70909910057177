import React, { useState, useCallback, useRef } from 'react';
import { DirectUpload } from "@rails/activestorage"
import _ from 'lodash';
import { createWorkCard, updateWorkCard } from "api/user_profile/user_resources/work_cards/api";
import { PROGRESS_BAR_EVENT, DIRECT_UPLOAD_URL } from 'constants/active_storage_upload';
import Dropzone from "../../shared/Dropzone";
import ImageList from "../../shared/ImageList";
import CheckboxContainer from "../../shared/CheckboxContainer";

const NewWorkCard = ({
                       workCard,
                       userProfileId,
                       authenticityToken,
                       resourceCategoriesWithSubcategories,
                       resourceCategories,
                       resourceSubCategories,
                    customCategoriesWithSubcategories,
                    customCategories,
                    customSubCategories,
                       backUrl,
                       resourceCategoryIds,
                       resourceSubCategoryIds,
                    customCategoryIds,
                    customSubCategoryIds,
                       coverProp,
                       imagesProp,
                       filesProp
                     }) => {

  const CONTENT_TYPES = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/rtf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.ms-powerpoint",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "image/jpeg",
    "image/jpg",
    "image/png"
  ]

  const defaultCatOrSubCat = categories => {
    const newCategories = new Map();
    _.map(categories, cat_id => {
      newCategories.set(cat_id.toString(), true)
    })
    return newCategories
  }

  const defaultCustCatOrSubCat = custCategories => {
    const newCategories = new Map();
    _.map(custCategories, cat_id => {
      newCategories.set(cat_id.toString(), true)
    })
    return newCategories
  }

  const defaultPaidOrFreeSecurity = () => {
    (workCard.paid_or_free == 'paid' && parseInt(workCard.price) > 0) ||
    (workCard.paid_or_free == 'free' && parseInt(workCard.price) == 0)
      ? false : true
  }

  const priceRef = useRef();
  const [status, setStatus] = useState(workCard.status)
  const [paidOrFree, setPaidOrFree] = useState(workCard.paid_or_free)
  const [paidOrFreeSecurity, setPaidOrFreeSecurity] = useState(defaultPaidOrFreeSecurity)
  const [price, setPrice] = useState(parseInt(workCard.price) || 3)
  const [legaly, setLegally] = useState(workCard.legaly == true ? true : false)
  const [title, setTitle] = useState(workCard.title ? workCard.title : '')
  const [promoText, setPromoText] = useState(workCard.promo_text || '')
  const [description, setDescription] = useState(workCard.description || '')
  const [categories, setCategories] = useState(defaultCatOrSubCat(resourceCategoryIds))
  const [subCategories, setSubCategories] = useState(defaultCatOrSubCat(resourceSubCategoryIds))
  
  const [custCategories, setCustomCategories] = useState(defaultCatOrSubCat(customCategoryIds))
  const [custSubCategories, setCustomSubCategories] = useState(defaultCatOrSubCat(customSubCategoryIds))

  const [tagList, setTagList] = useState(workCard.tag_list || '')

  const [cover, setCover] = useState(coverProp)
  const [images, setImages] = useState(imagesProp)
  const [files, setFiles] = useState(filesProp)

  const [errors, setErrors] = useState({})

  const [loaded, setLoaded] = useState(0);
  const [percent, setPercent] = useState(0);
  const [total, setTotal] = useState(1);

  // This func is used by DirectUpload invoked in uploadFile func
  const directUploadWillStoreFileWithXHR = (request) => {
    const { upload } = request
    upload.addEventListener(PROGRESS_BAR_EVENT.PROGRESS, (event) => directUploadDidProgress(event))
  }

  const directUploadDidProgress = (event) => {
    const { loaded, total } = event
    const calculatedLoaded = Math.round(loaded / 1000)
    const calculatedTotal = Math.round(total / 1000)
    const percent = Math.round((loaded / total) * 100)

    setLoaded(calculatedLoaded)
    setPercent(percent)
    setTotal(calculatedTotal)

    // this.setState({
    //   loaded: calculatedLoaded,
    //   percent,
    //   total: calculatedTotal,
    // })
  }

  const handleSubmit = e => {
    e.preventDefault();

    const formTaskData = new FormData(e.target);
    formTaskData.append('user_resources_work_card[status]', status);
    formTaskData.append('user_resources_work_card[legaly]', legaly ? '1' : '0');
    formTaskData.append('user_resources_work_card[paid_or_free]', paidOrFree);
    formTaskData.append('user_resources_work_card[title]', title);
    formTaskData.append('user_resources_work_card[promo_text]', promoText);
    formTaskData.append('user_resources_work_card[description]', description);
    formTaskData.append('user_resources_work_card[price]', `${price}`);
    formTaskData.append('user_resources_work_card[cover]', JSON.stringify(cover));
    formTaskData.append('user_resources_work_card[images]', JSON.stringify(images));
    formTaskData.append('user_resources_work_card[files]', JSON.stringify(files));
    formTaskData.append('user_resources_work_card[resource_category_ids]', JSON.stringify([...categories.entries()].filter(v => v[1] === true).map(([k]) => k)));
    formTaskData.append('user_resources_work_card[resource_subcategory_ids]', JSON.stringify([...subCategories.entries()].filter(v => v[1] === true).map(([k]) => k)));

    formTaskData.append('user_resources_work_card[custom_category_ids]', JSON.stringify([...custCategories.entries()].filter(v => v[1] === true).map(([k]) => k)));
    formTaskData.append('user_resources_work_card[custom_subcategory_ids]', JSON.stringify([...custSubCategories.entries()].filter(v => v[1] === true).map(([k]) => k)));

    formTaskData.append('user_resources_work_card[tag_list]', tagList);

    if (!validateForm(errors)) return;

    if (workCard.id > 0) {
      update(formTaskData)
    } else {
      create(formTaskData)
    }
  }

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val == true && (valid = false)
    );
    return valid;
  }

  const create = (formData) => {
    createWorkCard(formData, userProfileId, authenticityToken)
      .then(() => {
        window.location.assign(backUrl)
      })
      .catch(e => {
        setErrors(e.response.data.errors)
      });
  }

  const update = (formData) => {
    updateWorkCard(formData, workCard.id, userProfileId, authenticityToken)
      .then(() => {
        window.location.assign(backUrl)
      })
      .catch(e => {
        setErrors(e.response.data.errors)
      });
  }

  const uploadFile = (file, updateStateMethod) => {
    const upload = new DirectUpload(file, DIRECT_UPLOAD_URL);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        updateStateMethod(prevState => [
          ...prevState,
          {
            id: blob.id,
            signed_id:
            blob.signed_id,
            content_type: blob.content_type,
            filename: blob.filename,
            byte_size: blob.byte_size,
            key: blob.key,
            checksum: blob.checksum,
            service_url: blob.service_url
          }
        ]);
      }
    })
  }

  const onDrop1 = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      uploadFile(file, setCover)
    });
    setErrors(prevState => {
      return Object.assign({}, prevState, { cover: false });
    })
  }, []);

  const onDelete1 = image => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { cover: false });
    })
    setCover(prevState => (
      prevState.map(i => i.id != image.id ? i : Object.assign({}, i, { delete: true }))
    ))
  }

  const onDrop2 = useCallback(acceptedFiles => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { images: false });
    })
    acceptedFiles.map(file => {
      uploadFile(file, setImages)
    });
  }, []);
  const onDelete2 = image => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { images: false });
    })
    setImages(prevState => (
      prevState.map(i => i.id != image.id ? i : Object.assign({}, i, { delete: true }))
    ))
  }

  const onDrop3 = useCallback(acceptedFiles => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { files: false });
    })
    acceptedFiles.map(file => {
      uploadFile(file, setFiles)
    });
  }, []);
  const onDelete3 = image => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { files: false });
    })
    setFiles(prevState => (
      prevState.map(i => i.id != image.id ? i : Object.assign({}, i, { delete: true }))
    ))
  }

  const onLegallyChange = () => {
    setLegally(!legaly)
    setErrors(prevState => {
      return Object.assign({}, prevState, { legaly: false });
    })
  }

  const onStatusChange = ({ target }) => {
    setStatus(target.value);
  }

  const onPaidOrFreeChange = ({ target }) => {
    setPaidOrFree(target.value);

    if (target.value == 'free' && price > 0) {
      setPaidOrFreeSecurity(true)
      setErrors(prevState => {
        return Object.assign({}, prevState, { paidOrFreeSecurity: true });
      })
    } else if (target.value == 'paid' && price < 3) {
      setPaidOrFreeSecurity(true)
      setErrors(prevState => {
        return Object.assign({}, prevState, { paidOrFreeSecurity: true });
      })
    } else {
      setPaidOrFreeSecurity(false)
      setErrors(prevState => {
        return Object.assign({}, prevState, { paidOrFreeSecurity: false });
      })
    }
  }

  const onPriceChange = ({ target }) => {
    setPrice(target.value);

    if (paidOrFree == 'free' && target.value > 0) {
      setPaidOrFreeSecurity(true)
      setErrors(prevState => {
        return Object.assign({}, prevState, { paidOrFreeSecurity: true });
      })
    } else if (paidOrFree == 'paid' && target.value < 3) {
      setPaidOrFreeSecurity(true)
      setErrors(prevState => {
        return Object.assign({}, prevState, { paidOrFreeSecurity: true });
      })
    } else {
      setPaidOrFreeSecurity(false)
      setErrors(prevState => {
        return Object.assign({}, prevState, { paidOrFreeSecurity: false });
      })
    }
  }

  const onTitleChange = ({ target }) => {
    setTitle(target.value);

    setErrors(prevState => {
      return Object.assign({}, prevState, { title: false });
    })
  }

  const onPromoTextChange = ({ target }) => {
    if (target.value.length < 30 || promoText.length < 30) {
      setPromoText(target.value);
    }
  }

  const onDescriptionChange = ({ target }) => {
    setDescription(target.value);
  }

  const onSubCategoriesChange = (newState) => {
    const result = new Map([...subCategories].concat([...newState]));

    setSubCategories(result)
  }

  const onCategoriesChange = (newState) => {
    const result = new Map([...categories].concat([...newState]));

    setCategories(result)
    setErrors(prevState => {
      return Object.assign({}, prevState, { resource_categories: false });
    })
  }

 const onCustomSubCategoriesChange = (newState) => {
    const result = new Map([...custSubCategories].concat([...newState]));
    setCustomSubCategories(result)
  }

  const onCustomCategoriesChange = (newState) => {
    const result = new Map([...custCategories].concat([...newState]));

    setCustomCategories(result)
    setErrors(prevState => {
      return Object.assign({}, prevState, { custom_categories: false });
    })
  }

  const legallyClassNames = className => {
    if (errors && errors.legaly) return className + ' alert-danger';
    if (legaly == true) return  className + ' alert-success';
    if (legaly == false) return  className + ' alert-warning';
  }

  const onTagListChange = ({ target }) => {
    setTagList(target.value);
  }


  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="row">
        <aside className="col-lg-3" id="sidebar">

          <div className="box_style_work_card pt-6 p-4">
            <ul>
              <div className={`p-2 ${errors && errors.resource_categories ? 'text-danger' : ''}`}><i className="icon-check"></i>Kategorie główne <br /></div>
              <li>
                <fieldset
                  className="form-group check_boxes required user_resources_work_card_resource_categories form-group-valid">
                  <input type="hidden" name="user_resources_work_card[resource_category_ids][]" value=""/>
                  <CheckboxContainer items={resourceCategories} checkedItems={categories}
                                     setItems={onCategoriesChange}/>
                  {errors && errors.resource_categories && <small className="text-danger">
                    {errors && errors.resource_categories}
                  </small>}
                </fieldset>
              </li>
            </ul>
          </div>

        { customCategories &&
          <div className="box_style_work_card pt-6 p-4">
            <ul>
              <div className={`p-2 ${errors && errors.custom_categories ? 'text-danger' : ''}`}><i className="icon-check"></i>Kategorie główne <br /></div>
              <li>
                <fieldset
                  className="form-group check_boxes required user_website_custom_categories form-group-valid">
                  <input type="hidden" name="user_website[custom_category_ids][]" value="" />
                  <CheckboxContainer items={customCategories} checkedItems={custCategories}
                    setItems={onCustomCategoriesChange} />
                  {errors && errors.custom_categories && <small className="text-danger">
                    {errors && errors.custom_categories}
                  </small>}
                </fieldset>
              </li>
            </ul>
          </div>  
        }

        </aside>
        <div className="col-xl-9 col-lg-9">
          <div className="profile">
            <div className="row pt-2">
              <div className="col-md-3 pt-2"><i className="icon-check"></i> Widoczność <br/></div>
              <div className="col-md-3 pt-2"> Aktualnie zasób <br/>
                {status == 'accepted'
                  ? <span className="badge badge-success"> Dostępny publicznie </span>
                  : <span className="badge badge-danger"> Nieupubliczniony </span>}
              </div>
              <div className="form-group">
                <select className="form-control"
                        name="user_resources_work_card[status]"
                        id="user_resources_work_card_status"
                        value={status}
                        onChange={onStatusChange}>
                  <option value="not_accepted">Nieudostępniaj publicznie</option>
                  <option value="accepted">Udostępnij publicznie</option>
                </select>
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-md-3 pt-2"><i className="icon-check"></i> Rodzaj zasobu <br/></div>
              <div className="col-md-3 pt-2">
                {paidOrFree == 'free'
                  ? <span className="badge badge-success"> Zasób bezpłatny </span>
                  : <span className="badge badge-warning"> Zasób płatny </span>}
              </div>
              <div className="form-group">
                <select className="form-control"
                        name="user_resources_work_card[paid_or_free]"
                        id="user_resources_work_card_paid_or_free"
                        value={paidOrFree}
                        onChange={onPaidOrFreeChange}>
                  <option value="paid">Płatny</option>
                  <option value="free">Bezpłatny</option>
                </select>
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-md-3 pt-2"><i className="icon-check"></i> Cena zasobu w PLN <br/></div>
              <div className="col-md-3">
                <div className="form-group pt-2">
                  <input
                    ref={priceRef}
                    id="price"
                    name="price"
                    type="number"
                    placeholder='Wpisz cenę'
                    min={paidOrFree == 'paid' ? 3 : 0}
                    value={price}
                    onChange={onPriceChange}
                  />
                </div>
              </div>
            </div>

            {paidOrFreeSecurity &&
            <div className="row alert alert-danger">
              <div className="col-md-3 pt-2"><i className="icon-check"></i> Zabezpieczenie <br/>
                <span className="badge badge-danger"> Zadziałało zabezpieczenie </span>
              </div>
              <div className="col-md-9 pt-2">
                  <span><b>Zadziałało zabezpieczenie</b> w wyniku którego zasób nie będzie dostępny publicznie. <br/><br/>
                  Cena zasobu nie jest zgodna z jego rodzajem. Jeśli zasób jest bezpłatny to jego cena musi być równa 0 PLN,
                  jeśli zasób jest płatny, to jego cena musi być większa niż 0 PLN <br/><br/>
                  <b>Popraw cenę lub zmień rodzaj zasobu</b></span>
              </div>
            </div>}

            <div className={legallyClassNames("row pt-4 alert")}>
              <div className="col-md-3 pt-2"><i className="icon-check"></i> Prawo <br/>
                {errors && errors.legaly ? <span className="badge badge-danger"> Regulamin niezaakceptowany </span> : legaly === true ?
                  <span className="badge badge-success"> Regulamin zaakceptowany </span> :
                  <span className="badge badge-warning"> Regulamin niezaakceptowany </span>}
              </div>
              <div className="col-md-9 pt-2">
                <div className="form-group pt-2">
                  <input className="boolean optional"
                         type="checkbox"
                         value={legaly}
                         onChange={onLegallyChange}
                         checked={legaly ? 1 : 0}
                         id="user_resources_work_card_legaly"/>
                  <label className="checkbox"
                         htmlFor='user_resources_work_card_legaly'>
                    * Zapoznałem się z <b><a
                    target="_blank" href="/regulamin-sprzedazy-kart-pracy">Regulaminem Sprzedaży Kart
                    Pracy</a></b> oraz akceptuję jego warunki
                  </label>
                  <br/>
                  <small> <span className="text-primary">Aby zasób był prezentowany publicznie, należy zapoznać się z regulaminem i zaakceptować jego treść</span></small>
                </div>
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-md-12 pt-2">
                <div className={`${errors && errors.resource_subcategories ? 'text-danger' : ''}`}><i className="icon-check"></i>Dodatkowe podkategoriee <br /></div>
                <span className="text-primary">
                  <small>Dokładając staranności w wyborze kategorii i podkategorii sprawiasz, że Twoje pomoce są lepiej wyszukiwane</small>
                </span>
              </div>
              <table className="table table-striped">
                <tbody>
                {
                  _.chunk(resourceCategoriesWithSubcategories, 4).map((rowTasks, idx) => {
                    return <tr key={idx}>
                      {rowTasks.map((main_category, idx) => {
                        return (
                          <td key={idx} style={{ width: '25%', border: 'none' }}>
                            <label><b>{main_category.name}</b></label>
                            <CheckboxContainer
                              checkedItems={subCategories}
                              items={resourceSubCategories.filter(e => e.resource_category_id == main_category.id)}
                              setItems={onSubCategoriesChange}
                            />
                          </td>
                        )
                      })}
                    </tr>
                  })
                }
                </tbody>
              </table>
            </div>

            
            <div className="row pt-2">
              <div className="col-md-12 pt-2">
                <div className={`${errors && errors.custom_subcategories ? 'text-danger' : ''}`}><i className="icon-check"></i>Własne podkategorie <br /></div>
                <span className="text-primary">
                  <small>Dokładając staranności w wyborze kategorii i podkategorii sprawiasz, że Twoje pomoce są lepiej wyszukiwane</small>
                </span>
              </div>
              <table className="table table-striped">
                <tbody>
                  {
                    _.chunk(customCategoriesWithSubcategories, 4).map((rowTasks, idx) => {
                      return <tr key={idx}>
                        {rowTasks.map((main_category, idx) => {
                          return (
                            <td key={idx} style={{ width: '25%', border: 'none' }}>
                              <label><b>{main_category.name}</b></label>
                              <CheckboxContainer
                                checkedItems={custSubCategories}
                                items={customSubCategories.filter(e => e.custom_category_id == main_category.id)}
                                setItems={onCustomSubCategoriesChange}
                              />
                            </td>
                          )
                        })}
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>


            <div className="row pt-2">
              <div className="col-md-12">
                <p className={`mb-1 ${errors && errors.title && 'text-danger'}`}><small><i className="icon-edit"></i> Nazwa zasobu
                  z kartami pracy</small></p>
                <input
                  className={`w-100 borders form-control ${errors && errors.title && 'is-invalid'}`}
                  id="title"
                  name="title"
                  type="text"
                  placeholder='Wpisz nazwę'
                  value={title}
                  onChange={onTitleChange}
                />
                {errors && errors.title && <small className="text-danger">
                  {errors && errors.title}
                </small>}
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-md-12">
                <p className={`mb-1 ${errors && errors.promo_text && 'text-danger'}`}><small><i className="icon-edit"></i> Slogan
                  marketingowy</small></p>
                <small>
                  <span className="text-primary">Pozostała liczba znaków <span
                    className="text-danger countchars_brand_text">{30 - promoText.length}</span></span>
                </small>
                <input
                  className={`w-100 borders form-control ${errors && errors.promo_text && 'is-invalid'}`}
                  id="promo_text"
                  name="promo_text"
                  type="text"
                  placeholder='Slogan do 30 znaków'
                  value={promoText}
                  onChange={onPromoTextChange}
                />
                {errors && errors.promo_text && <small className="text-danger">
                  {errors && errors.promo_text}
                </small>}
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-md-12">
                <p className={`mb-1 ${errors && errors.description && 'text-danger'}`}><small><i className="icon-edit"></i> Krótki
                  opis zasobu</small></p>                  
                <textarea
                  className={`w-100 borders form-control higer ${errors && errors.description && 'is-invalid'}`}
                  id="description"
                  name="description"
                  type="text"
                  placeholder='Wpisz nazwę'
                  value={description}
                  onChange={onDescriptionChange}
                />
                {errors && errors.description && <small className="text-danger">
                  {errors && errors.description}
                </small>}
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-md-12">
                <p className={`mb-1 ${errors && errors.tag_list && 'text-danger'}`}><small><i className="icon-edit"></i> Tagi</small></p>
                <small>
                  <span className="text-primary">Tagi oddzielaj od siebie przecinkami </span>
                </small>
                <textarea
                  className={`w-100 borders form-control ${errors && errors.tag_list && 'is-invalid'}`}
                  id="tag_list"
                  name="tag_list"
                  type="text"
                  placeholder='Wpisz nazwę'
                  value={tagList}
                  onChange={onTagListChange}
                />
                {errors && errors.tag_list && <small className="text-danger">
                  {errors && errors.tag_list}
                </small>}
              </div>
            </div>

            {/*   images_start   */}

            <div className="row pt-2">
              <div className={`col-md-12 pt-2 ${errors && errors.cover && 'text-danger'}`}><i className="icon-check"></i> Główna
                grafika <br/>
                <Dropzone className={'error'} onDrop={onDrop1} accept={"image/png,image/jpg,image/jpeg"}
                          multiple={false} error={errors && errors.cover ? true : false}/>
                <ImageList onDelete={onDelete1} images={cover}/>
                {errors && errors.cover && <small className="text-danger">
                  {errors && errors.cover}
                </small>}
              </div>
            </div>

            <div className="row pt-2">
              <div className={`col-md-12 pt-2 ${errors && errors.images && 'text-danger'}`}><i
                className="icon-check"></i> Miniaturki <br/>
                <Dropzone onDrop={onDrop2} accept={CONTENT_TYPES.join(',')}
                          error={errors && errors.images ? true : false}/>
                <ImageList onDelete={onDelete2} images={images}/>
                {errors && errors.images && <small className="text-danger">
                  {errors && errors.images}
                </small>}
              </div>
            </div>

            <div className="row pt-2">
              <div className={`col-md-12 pt-2 ${errors && errors.files && 'text-danger'}`}><i className="icon-check"></i> Pliki z
                materiałami do pobrania <br/>
                <Dropzone onDrop={onDrop3} accept={CONTENT_TYPES.join(',')+",application/pdf"}
                          error={errors && errors.files ? true : false}/>
                <ImageList onDelete={onDelete3} images={files}/>
                {errors && errors.files && <small className="text-danger">
                  {errors && errors.files}
                </small>}
              </div>
            </div>

            {/*   images_end   */}

            <div className="form-group pt-5">
              <div className="col text-center">
                <button type='submit' className='btn_1 rounded'>{workCard.id > 0 ? 'Zaktualizuj' : 'Zapisz'}</button>
              </div>
            </div>

            <div className="row">
              <div className="col text-center">
                <a className='btn_cstm btn_cstm_25 btn_cstm-soft text-danger' href={backUrl}>Kliknij tutaj, jeśli nie
                  chcesz
                  edytować tej sekcji</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

export default NewWorkCard;
