import React from 'react';
import ImageItem from "./ImageItem";
import MessageItem from "./MessageItem";

const ChatItem = ({message, userName}) => {
  const isOwnMessage = () => {
    return message.state.author === userName
  };

  const formatMinutes = () => {
    let minutes = message.state.timestamp.getMinutes();

    if (minutes.toString().length == 1) {
      return `0${minutes}`
    }

    return minutes
  }

  const renderMessage = () => {
    if (message.state.type === 'media') {
      return <ImageItem message={message.state} />
    } else {
      return <MessageItem message={message.state} />
    }
  }

  return (
    <li className={`media ${isOwnMessage() ? 'sent' : 'received'}`}>
      <div className="media-body">
        <div className="msg-box">
          <div>
            {renderMessage()}
            <ul className="chat-msg-info">
              <li>
                <div className="chat-time">
                  <span>{`${message.state.timestamp.getHours()}:${formatMinutes()}`}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChatItem;
