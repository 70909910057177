import React from "react";
import pdfImg from "./pdf_icon.jpg";

// Rendering individual images
const Image = ({ image, handleDelete }) => {
  if (image.delete == undefined) {
    return (
      <div className="file-item">
        <img alt={`img - ${image.id}`} src={image.content_type == 'application/pdf' ? pdfImg : image.service_url}
             className="file-img"/>
        <button onClick={() => handleDelete(image)} className="btn btn-danger">x</button>
        <span>{image.filename}</span>
      </div>
    );
  } else {
    return null;
  }
};

// ImageList Component
const ImageList = ({ images, onDelete }) => {

  // render each image by calling Image component
  const renderImage = (image, index) => {
    return (
      <Image
        image={image}
        key={image.id}
        handleDelete={handleDelete}
      />
    );
  };

  const handleDelete = image => {
    onDelete(image)
  }

  // Return the list of files
  return <section className="file-list">{images.map(renderImage)}</section>;
};

export default ImageList;
