import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Card from "./Card";

class CardList extends Component {
  state = {
    activeCard: "",
    activeCards: [],
    win: null,
    matchedPairs: 0,
    clicks: 0,
  };

  cardClicked = (cardToFlip) => {
    this.setState({ clicks: this.state.clicks + 1 });
    const flippedCard = { ...cardToFlip, flip: !cardToFlip.flip };
    const cards = this.props.cards.map((card) =>
      card === cardToFlip ? flippedCard : card
    );
    this.props.flipCards(cards);

    // It's first click?
    if (this.state.activeCard === "") {
      this.setState({ activeCard: flippedCard });
      return;
    }
    // It's second click?
    else {
      const { activeCard } = this.state;
      if (flippedCard !== activeCard && flippedCard.id === activeCard.id) {
        this.setState({ matchedPairs: this.state.matchedPairs + 1 });
        setTimeout(() => {
          this.props.flipCards(
            this.props.cards.map((c) =>
              c.id === flippedCard.id
                ? { ...c, off: true, flip: false }
                : { ...c, flip: false }
            )
          );
        }, 1000);
        // if (this.state.matchedPairs == this.props.cards.length / 2 - 1) {
        //   alert("Wygrałeś")
        // }
      } else {
        setTimeout(() => {
          this.props.flipCards(
            this.props.cards.map((c) => ({ ...c, flip: false }))
          );
        }, 1000);
      }
      this.setState({ activeCard: "" });
    }
  };

  renderCards = (cards) =>
    cards.map((card, idx) => (
      <Card key={idx} cardClicked={this.cardClicked} card={card} />
    ));

  render() {
    return (
      <Fragment>
        {this.props.cards && this.renderCards(this.props.cards)}
      </Fragment>
    );
  }
}

CardList.propTypes = {};

export default CardList;
