import React, { useState } from 'react';

const ChatForm = ({ handleSubmit, setFullscreenEnabled }) => {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);

  const handleMessageChange = e => {
    setMessage(e.target.value);
  }

  const handleFileChange = e => {
    setFile(e.target.files[0])
    setFullscreenEnabled(false)
  }

  const onHandleSubmit = e => {
    e.preventDefault();
    handleSubmit(message, file)
    setMessage('')
    setFile(null)
  }

  return (
    <form onSubmit={onHandleSubmit}>
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="btn-file btn">
            <i className="fa fa-paperclip"></i>
            <input onClick={() => setFullscreenEnabled(false)} files={[file]} onChange={handleFileChange} type="file"/>
          </div>
        </div>
        <input type="text" value={message} onChange={handleMessageChange} className="input-msg-send form-control"
               placeholder="Napisz wiadomość"/>
        <div className="input-group-append">
          <button type="submit" className="btn msg-send-btn"><i className="fab fa-telegram-plane"></i></button>
        </div>
      </div>
    </form>
  );
};

export default ChatForm;
