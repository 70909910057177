import React from "react";
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from "react-dropzone";
import PropTypes from 'prop-types';
import './Dropzone.css'

const Dropzone = ({ onDrop, accept, multiple }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: multiple
  });

  const getClassName = (className, isActive) => {
    if (!isActive) return className;
    return `${className} ${className}-active`;
  };

  /*
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  return (
    <div className={getClassName("dropzone", isDragActive)} {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center-dropzone">
        {isDragActive ? (
          <p className="dropzone-content">Puść aby umieścić zdjęcia w formularzu</p>
        ) : (
          <p className="dropzone-content">
            Przeciągnij wybrane zdjęcia, bądź kliknij aby wybrać
          </p>
        )}
      </div>
    </div>
  );
};

Dropzone.defaultProps = {
  multiple: true
}

export default Dropzone;
