import React, { Component } from "react";
import axios from "axios";
import ChatForm from "./ChatForm";
import ChatItem from "./ChatItem";

class ChatScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      loading: false,
      channel: null,
    };

    this.scrollDiv = React.createRef();
  }

  componentDidMount = async () => {
    const { userName, chatSid } = this.props || {};
    let token = "";

    this.setState({ loading: true });

    try {
      token = await this.getToken(userName);
    } catch {
      throw new Error("Unable to get token, please reload this page");
    }

    Twilio.Chat.Client.create(token).then(async client => {
      client.on("tokenAboutToExpire", async () => {
        const token = await this.getToken(userName);
        client.updateToken(token);
      });

      client.on("tokenExpired", async () => {
        const token = await this.getToken(userName);
        client.updateToken(token);
      });

      client.on("channelJoined", async (channel) => {
        // getting list of all messages since this is an existing channel
        const messages = await channel.getMessages();
        this.setState({ messages: messages.items || [] });
        this.scrollToBottom();
      });

      try {
        const channel = await client.getChannelBySid(chatSid);

        this.joinChannel(channel);
      } catch (err) {
        throw new Error("Unable to find channel, please reload this page");
      }
    });
  }

  sendMessage = (message, file) => {
    const { channel } = this.state;
    if (file) {
      this.setState({ loading: true });

      const formData = new FormData();
      formData.append('file', file);
      channel.sendMessage(formData);

      this.setState({ loading: false });
    }
    if (message) {
      this.setState({ loading: true });
      channel.sendMessage(String(message).trim());
      this.setState({ loading: false });
    }
  };

  joinChannel = async (channel) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }

    this.setState({
      channel: channel,
      loading: false
    });

    channel.on("messageAdded", this.handleMessageAdded);
    this.scrollToBottom();
  };

  getToken = async (name) => {
    const response = await axios.get(`/chat/token/${name}`);
    const { data } = response;
    return data.token;
  }

  handleMessageAdded = (message) => {
    const { messages } = this.state;
    this.setState({
        messages: [...messages, message],
      },
      this.scrollToBottom
    );
    this.props.onNewChatMessageUpdate();
  };

  scrollToBottom = () => {
    const scrollHeight = this.scrollDiv.current.scrollHeight;
    const height = this.scrollDiv.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  render() {
    const { messages } = this.state;
    const { chatEnabled, userName, setFullscreenEnabled } = this.props;

    return (
      <div className={`call-chat ${chatEnabled && "show"}`}>
        <div className="chat-body">
          <div className="chat-scroll" ref={this.scrollDiv}>
            <ul className="list-unstyled">
              {messages &&
              messages.map(message => {
                return <ChatItem key={message.index} message={message} userName={userName}/>
              })}
            </ul>
          </div>
        </div>
        <div className="chat-footer">
          <ChatForm handleSubmit={this.sendMessage} setFullscreenEnabled={setFullscreenEnabled} />
        </div>
      </div>
    )
  }
}

export default ChatScreen;
