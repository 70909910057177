import React, { useEffect, useState } from 'react';
import moment from 'moment';

const Timer = ({ startDate }) => {
  const [date, setDate] = useState(Date.now() - startDate)

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(Date.now() - startDate)
    }, 1000)

    return () => {
      clearInterval(timer)
    };
  })

  return (
    <div>
      <span>{moment.utc(date).format("HH:mm:ss")}</span>
    </div>
  );
};

Timer.defaultProps = {
  startDate: Date.now()
}

export default Timer;
