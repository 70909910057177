import React from 'react';

class ImageItem extends React.Component { // you can't use stateless component because you need a state
  constructor () {
    super()
    this.state = {}
  }
  componentDidMount() {
    this.props.message.media.getContentTemporaryUrl().then((value) => {
      this.setState({val: value})
    })
  }
  render () {
    if (!this.state.val) return null
    return <div className="chat-msg-attachments">
      <div className="chat-attachment">
        <img src={this.state.val} alt="Attachment" />
          <div className="chat-attach-caption">{this.props.message.media.filename}</div>
          <a href={this.state.val} target='_blank' className="chat-attach-download">
            <i className="fas fa-download"></i>
          </a>
      </div>
    </div>
  }
}

export default ImageItem;
