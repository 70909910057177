import React, { useState, useCallback, useRef } from 'react';
import { DirectUpload } from "@rails/activestorage"
import cuid from 'cuid';
import _ from 'lodash';
import { createTutorial, updateTutorial } from "api/user_profile/user_resources/tutorials/api";
import { PROGRESS_BAR_EVENT, DIRECT_UPLOAD_URL } from 'constants/active_storage_upload';
import Dropzone from "../../shared/Dropzone";
import ImageList from "../../shared/ImageList";
import CheckboxContainer from "../../shared/CheckboxContainer";
import ChapterInputs from '../../shared/ChapterInputs';

const NewTutorial = ({
                      tutorial,
                      userProfileId,
                      authenticityToken,
                      admin,
                      backUrl,
                      coverProp,
                      imagesProp,
                      filesProp
                     }) => {

  const CONTENT_TYPES = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/rtf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.ms-powerpoint",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "image/jpeg",
    "image/jpg",
    "image/png"
  ]

  // const defaultCatOrSubCat = categories => {
  //   const newCategories = new Map();
  //   _.map(categories, cat_id => {
  //     newCategories.set(cat_id.toString(), true)
  //   })
  //   return newCategories
  // }

  // const defaultPaidOrFreeSecurity = () => {
  //   (tutorial.paid_or_free == 'paid' && parseInt(tutorial.price) > 0) ||
  //   (tutorial.paid_or_free == 'free' && parseInt(tutorial.price) == 0)
  //     ? false : true
  // }

  // const priceRef = useRef();
  const [status, setStatus] = useState(tutorial.status)
  // const [paidOrFree, setPaidOrFree] = useState(tutorial.paid_or_free)
  // const [paidOrFreeSecurity, setPaidOrFreeSecurity] = useState(defaultPaidOrFreeSecurity)
  // const [price, setPrice] = useState(parseInt(tutorial.price) || 3)
  const [legally, setLegally] = useState(tutorial.legally == true ? true : false)
  const [imported, setImported] = useState(tutorial.imported == true ? true : false)
  const [videoId, setVideoId] = useState(tutorial.video_id ? tutorial.video_id : '')


  const [published, setPublished] = useState(tutorial.published == true ? true : false)
  const [destination, setDestination] = useState(tutorial.destination)
  const [priority, setPriority] = useState(tutorial.priority || '')

  const [paid, setPaid] = useState(tutorial.paid == true ? true : false)
  const [title, setTitle] = useState(tutorial.title ? tutorial.title : '')
  const [promoText, setPromoText] = useState(tutorial.promo_text || '')
  const [promoVideo, setPromoVideo] = useState(tutorial.promo_video || '')
  const [description, setDescription] = useState(tutorial.description || '')
  const [ownCode, setOwnCode] = useState(tutorial.own_code || '')

  const [youtube, setYoutube] = useState(tutorial.youtube || '')
  const [vimeo, setVimeo] = useState(tutorial.vimeo || '')

  const [cover, setCover] = useState(coverProp)
  const [images, setImages] = useState(imagesProp)
  const [files, setFiles] = useState(filesProp)

  const [errors, setErrors] = useState({})

  const [loaded, setLoaded] = useState(0);
  const [percent, setPercent] = useState(0);
  const [total, setTotal] = useState(1);

  // This func is used by DirectUpload invoked in uploadFile func
  const directUploadWillStoreFileWithXHR = (request) => {
    const { upload } = request
    upload.addEventListener(PROGRESS_BAR_EVENT.PROGRESS, (event) => directUploadDidProgress(event))
  }

  const directUploadDidProgress = (event) => {
    const { loaded, total } = event
    const calculatedLoaded = Math.round(loaded / 1000)
    const calculatedTotal = Math.round(total / 1000)
    const percent = Math.round((loaded / total) * 100)

    setLoaded(calculatedLoaded)
    setPercent(percent)
    setTotal(calculatedTotal)
  }

  const handleSubmit = e => {
    e.preventDefault();

    const formTaskData = new FormData(e.target);
    formTaskData.append('user_resources_tutorial[status]', status);
    formTaskData.append('user_resources_tutorial[priority]', priority);
    formTaskData.append('user_resources_tutorial[destination]', destination);
    
    formTaskData.append('user_resources_tutorial[legally]', legally ? '1' : '0');
    
    formTaskData.append('user_resources_tutorial[imported]', imported ? '1' : '0');
    formTaskData.append('user_resources_tutorial[video_id]', videoId);

    formTaskData.append('user_resources_tutorial[paid]', paid ? '1' : '0');
    formTaskData.append('user_resources_tutorial[published]', published ? '1' : '0');

    formTaskData.append('user_resources_tutorial[title]', title);
    formTaskData.append('user_resources_tutorial[promo_text]', promoText);
    formTaskData.append('user_resources_tutorial[promo_video]', promoVideo);
    formTaskData.append('user_resources_tutorial[description]', description);
    formTaskData.append('user_resources_tutorial[own_code]', ownCode);

    formTaskData.append('user_resources_tutorial[youtube]', youtube);
    formTaskData.append('user_resources_tutorial[vimeo]', vimeo);

    formTaskData.append('user_resources_tutorial[cover]', JSON.stringify(cover));
    formTaskData.append('user_resources_tutorial[images]', JSON.stringify(images));
    formTaskData.append('user_resources_tutorial[files]', JSON.stringify(files));
 
    if (!validateForm(errors)) return;

    if (tutorial.id > 0) {
      update(formTaskData)
    } else {
      create(formTaskData)
    }
  }

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val == true && (valid = false)
    );
    return valid;
  }

  const create = (formData) => {
    createTutorial(formData, userProfileId, authenticityToken)
      .then(() => {
        window.location.assign(backUrl)
      })
      .catch(e => {
        setErrors(e.response.data.errors)
      });
  }

  const update = (formData) => {
    updateTutorial(formData, tutorial.id, userProfileId, authenticityToken)
      .then(() => {
        window.location.assign(backUrl)
      })
      .catch(e => {
        setErrors(e.response.data.errors)
      });
  }

  const uploadFile = (file, updateStateMethod) => {
    const upload = new DirectUpload(file, DIRECT_UPLOAD_URL);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        updateStateMethod(prevState => [
          ...prevState,
          {
            id: blob.id,
            signed_id:
            blob.signed_id,
            content_type: blob.content_type,
            filename: blob.filename,
            byte_size: blob.byte_size,
            key: blob.key,
            checksum: blob.checksum,
            service_url: blob.service_url
          }
        ]);
      }
    })
  }

  const onDrop1 = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      uploadFile(file, setCover)
    });
    setErrors(prevState => {
      return Object.assign({}, prevState, { cover: false });
    })
  }, []);

  const onDelete1 = image => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { cover: false });
    })
    setCover(prevState => (
      prevState.map(i => i.id != image.id ? i : Object.assign({}, i, { delete: true }))
    ))
  }

  const onDrop2 = useCallback(acceptedFiles => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { images: false });
    })
    acceptedFiles.map(file => {
      uploadFile(file, setImages)
    });
  }, []);
  const onDelete2 = image => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { images: false });
    })
    setImages(prevState => (
      prevState.map(i => i.id != image.id ? i : Object.assign({}, i, { delete: true }))
    ))
  }

  const onDrop3 = useCallback(acceptedFiles => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { files: false });
    })
    acceptedFiles.map(file => {
      uploadFile(file, setFiles)
    });
  }, []);
  const onDelete3 = image => {
    setErrors(prevState => {
      return Object.assign({}, prevState, { files: false });
    })
    setFiles(prevState => (
      prevState.map(i => i.id != image.id ? i : Object.assign({}, i, { delete: true }))
    ))
  }

  const onLegallyChange = () => {
    setLegally(!legally)
    setErrors(prevState => {
      return Object.assign({}, prevState, { legally: false });
    })
  }

  const onPaidChange = () => {
    setPaid(!paid)
    setErrors(prevState => {
      return Object.assign({}, prevState, { paid: false });
    })
  }

  const onImportedChange = () => {
    setImported(!imported)
    setErrors(prevState => {
      return Object.assign({}, prevState, { imported: false });
    })
  }

  const onPublishedChange = () => {
    setPublished(!published)
    setErrors(prevState => {
      return Object.assign({}, prevState, { published: false });
    })
  }



  const onStatusChange = ({ target }) => {
    setStatus(target.value);
  }

  const onPriorityChange = ({ target }) => {
    setPriority(target.value);
  }

  const onDestinationChange = ({ target }) => {
    setDestination(target.value);
  }

  const onTitleChange = ({ target }) => {
    setTitle(target.value);

    setErrors(prevState => {
      return Object.assign({}, prevState, { title: false });
    })
  }

  const onVideoIdChange = ({ target }) => {
    setVideoId(target.value);

    setErrors(prevState => {
      return Object.assign({}, prevState, { video_id: false });
    })
  }

  const onPromoTextChange = ({ target }) => {
    if (target.value.length < 30 || promoText.length < 30) {
      setPromoText(target.value);
    }
  }

  const onPromoVideoChange = ({ target }) => {
    setPromoVideo(target.value);
  }

  const onDescriptionChange = ({ target }) => {
    setDescription(target.value);
  }

  const onOwnCodeChange = ({ target }) => {
    setOwnCode(target.value);
  }

  const onYoutubeChange = ({ target }) => {
    setYoutube(target.value);
  }

  const onVimeoChange = ({ target }) => {
    setVimeo(target.value);
  }

  const legallyClassNames = className => {
    if (errors && errors.legally ) return className + ' alert-danger';
    if (legally == true) return  className + ' alert-success';
    if (legally == false) return  className + ' alert-warning';
  }

  const importedClassNames = className => {
    if (imported == true) return className + ' d-none';
    if (imported == false) return className + '';
  }


  const paidClassNames = className => {
    if (errors && errors.paid) return className + ' alert-danger';
    if (paid == true) return className + ' alert-primary';
    if (paid == false) return className + ' alert-warning';
  }

  const publishedClassNames = className => {
    if (published == true) return className + ' d-none';
    if (published == false) return className + '';
  }

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="profile">

            {admin &&
              <div className="row pt-2">
                <div className="col-md-3 pt-2">
                  {status == 'accepted'
                    ? <span className="badge badge-success"> Dostępne publicznie </span>
                    : <span className="badge badge-danger"> Szkolenie nieupublicznione </span>}
                </div>
                <div className="col-md-3 pt-2">
                  <div className="form-group">
                    <select className="form-control"
                      name="user_resources_tutorial[status]"
                      id="user_resources_tutorial_status"
                      value={status}
                      onChange={onStatusChange}>
                      <option value="not_accepted">Nieudostępniaj publicznie</option>
                      <option value="accepted">Udostępnij publicznie</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3 pt-2">
                  <div className="form-group">
                    <select className="form-control"
                      name="user_resources_tutorial[destination]"
                      id="user_resources_tutorial_destination"
                      value={destination}
                      onChange={onDestinationChange}>
                      <option value="all_destinations">Wszystkie poniższe</option>
                      <option value="website">Własna witryna</option>
                      <option value="logopeda">Logopeda</option>
                      <option value="warsztatlogopedy">Warsztat logopedy</option>
                      <option value="logopedyczne">Logopedyczne</option>
                      <option value="pomocelogopedy">Pomoce logopedy</option>
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                </div>
              </div>
            }


            <div className={legallyClassNames("row pt-4 alert")}>
              <div className="col-md-12">
                <h5> Stworzyć regulamin dla Twórców szkoleń </h5>             
              </div>
              <div className="col-md-3 pt-2"><i className="icon-check"></i> Prawo <br />
                {(errors && errors.legally) ? <span className="badge badge-danger"> Regulamin niezaakceptowany </span> : legally === true ?
                  <span className="badge badge-success"> Regulamin zaakceptowany </span> :
                  <span className="badge badge-warning"> Regulamin niezaakceptowany </span>}
              </div>
              <div className="col-md-9 pt-2">
                <div className="form-group pt-2">
                  <input className="boolean optional"
                    type="checkbox"
                    value={legally}
                    onChange={onLegallyChange}
                    checked={legally ? 1 : 0}
                    id="user_resources_tutorial_legally" />
                  <label className="checkbox"
                    htmlFor='user_resources_tutorial_legally'>
                    * Zapoznałem się z <b><a
                      target="_blank" href="/regulamin-sprzedazy-kart-pracy">Regulaminem Sprzedaży Kart
                    Pracy</a></b> oraz akceptuję jego warunki
                  </label>
                  <br />
                  <small> <span className="text-primary">Aby zasób był prezentowany publicznie, należy zapoznać się z regulaminem i zaakceptować jego treść</span></small>
                </div>
              </div>
            </div>

            <div className="col-12">
              <hr />
            </div>

            <div className={paidClassNames("row pt-4 alert")}>
              <div className="col-md-3 pt-2"><i className="icon-check"></i> Sprzedaż <br />
                {(errors && errors.paid) ? <span className="badge badge-danger"> Rodzaj zasobu </span> : paid === true ?
                  <span className="badge badge-primary"> Zasób płatny </span> :
                  <span className="badge badge-danger"> Zasób bezpłatny </span>}
              </div>
              <div className="col-md-9 pt-2">
                <div className="form-group pt-2">
                  <input className="boolean optional"
                    type="checkbox"
                    value={paid}
                    onChange={onPaidChange}
                    checked={paid ? 1 : 0}
                    id="user_resources_tutorial_paid" />
                  <label className="checkbox"
                    htmlFor='user_resources_tutorial_paid'>
                    * Sprawdź dobrze zanim zapiszesz informację, że zasób jest bezpłatny
                  </label>
                  <br />
                </div>
              </div>
            </div>

            <div className="col-12">
              <hr />
            </div>

            <div className="row pt-2">
              <div className="col-md-3 pt-2"> <i className="icon-youtube"></i> Import z Youtube <br />
                { imported === true
                  ? <span className="badge badge-danger"> Importowany </span>
                  : <span className="badge badge-danger"> Nieimportowany </span>}
              </div>
              <div className="col-md-3 pt-2">
                <div className="form-group pt-2">
                  <input className="boolean optional"
                    type="checkbox"
                    value={imported}
                    onChange={onImportedChange}
                    checked={imported ? 1 : 0}
                    id="user_resources_tutorial_imported" />
                  <label className="checkbox"
                    htmlFor='user_resources_tutorial_imported'>
                    * Zasób importowany z Youtube
                  </label>
                  <br />
                </div>
              </div>

              <div className="col-md-3">
                <input
                  className={`w - 100 borders form-control ${errors && errors.priority && 'is-invalid'}`}
                  id="video_id"
                  name="video_id"
                  type="text"
                  placeholder='Wpisz id'
                  value={videoId}
                  onChange={onVideoIdChange}
                />
              </div>

            </div>

            <div className="col-12">
              <hr />
            </div>

            <div className="row pt-2">


              <div className="col-md-3 pt-2"> <i className="icon-eye"></i> Widoczność <br />
                {published === true
                  ? <span className="badge badge-success"> Opublikowany </span>
                  : <span className="badge badge-danger"> Nieopublikowany </span>}
              </div>
              <div className="col-md-3 pt-2">
                <div className="form-group pt-2">
                  <input className="boolean optional"
                    type="checkbox"
                    value={published}
                    onChange={onPublishedChange}
                    checked={published ? 1 : 0}
                    id="user_resources_tutorial_published" />
                  <label className="checkbox"
                    htmlFor='user_resources_tutorial_published'>
                    * Zasób opublikowany
                  </label>
                  <br />
                </div>
              </div>


              <div className="col-md-3">
                <input
                  className={`w - 100 borders form-control ${errors && errors.priority && 'is-invalid'}`}
                  id="priority"
                  name="priority"
                  type="text"
                  placeholder='Kolejność na liście'
                  value={priority}
                  onChange={onPriorityChange}
                />
                {errors && errors.priority && <small className="text-danger">
                  {errors && errors.priority}
                </small>}
              </div>
            </div>


            <div className="col-12" >
              <hr />
            </div>

            <div className={importedClassNames()}>
              
              <div className="row pt-2">
                <div className="col-md-6">
                  <p className={`mb-1 ${errors && errors.title && 'text-danger'}`}><small><i className="icon-edit"></i> Tytuł szkolenia</small></p>
                  <input
                    className={`w - 100 borders form-control ${errors && errors.title && 'is-invalid'}`}
                    id="title"
                    name="title"
                    type="text"
                    placeholder='Wpisz nazwę'
                    value={title}
                    onChange={onTitleChange}
                  />
                  {errors && errors.title && <small className="text-danger">
                    {errors && errors.title}
                  </small>}
                </div>

                <div className="col-md-6">
                  <p className={`mb-1 ${errors && errors.promo_text && 'text-danger'}`}><small><i className="icon-edit"></i> Slogan
                    marketingowy</small></p>
                  <input
                    className={`w - 100 borders form-control ${errors && errors.promo_text && 'is-invalid'}`}
                    id="promo_text"
                    name="promo_text"
                    type="text"
                    placeholder='Slogan do 30 znaków'
                    value={promoText}
                    onChange={onPromoTextChange}
                  />
                  {errors && errors.promo_text && <small className="text-danger">
                    {errors.promo_text}
                  </small>}
                  <small>
                    <span className="text-primary">Pozostała liczba znaków <span
                      className="text-danger countchars_brand_text">{30 - promoText.length}</span></span>
                  </small>
                </div>
              </div>

              <div className="row pt-2">

                <div className="col-md-12">
                  <p className={`mb-1 ${errors && errors.description && 'text-danger'}`}><small><i className="icon-edit"></i> Krótki
                    opis</small></p>
                  <textarea
                    className={`w - 100 borders form-control ${errors && errors.description && 'is-invalid'}`}
                    style={{ minHeight: '200px'}}
                    id="description"
                    name="description"
                    type="text"
                    placeholder='Wpisz nazwę'
                    value={description}
                    onChange={onDescriptionChange}
                  />
                  {errors && errors.description && <small className="text-danger">
                    {errors && errors.description}
                  </small>}
                </div>
              </div>

              <div className="row pt-2">
                <div className="col-md-12">
                  <p className={`mb-1 ${errors && errors.own_code && 'text-danger'}`}><small><i className="icon-edit"></i>
                    Własny kod</small></p>
                  <textarea
                    className={`w-100 borders form-control ${errors && errors.own_code && 'is-invalid'}`}
                    style={{ minHeight: '200px' }}
                    id="own_code"
                    name="own_code"
                    type="text"
                    placeholder='Wpisz treść'
                    value={ownCode}
                    onChange={onOwnCodeChange}
                  />
                  {errors && errors.own_code && <small className="text-danger">
                    {errors && errors.own_code}
                  </small>}
                </div>
              </div>

              <div className="row pt-2">

                <div className="col-md-4">
                  <p className="mb-1"><small><i className="icon-edit"></i> Video promocyjne
                    </small></p>
                  <input
                    className={`w - 100 borders form-control ${errors && errors.promo_video && 'is-invalid'}`}
                    id="promo_video"
                    name="promo_video"
                    type="text"
                    placeholder='Podaj link'
                    value={promoVideo}
                    onChange={onPromoVideoChange}
                  />
                  {errors && errors.promo_video && <small className="text-danger">
                    {errors.promo_video}
                  </small>}
                </div>

                <div className="col-md-4">
                  <p className={`mb-1 ${errors && errors.youtube && 'text-danger'}`}><small><i className="icon-edit"></i>
                    ID Youtube <span class="badge badge-warning"> Tylko ID </span></small></p>
                  <textarea
                    className={`w - 100 borders form-control ${errors && errors.youtube && 'is-invalid'}`}
                    id="yotube"
                    name="yotube"
                    type="text"
                    placeholder='Podaj link'
                    value={youtube}
                    onChange={onYoutubeChange}
                  />
                  {errors && errors.youtube && <small className="text-danger">
                    {errors && errors.youtube}
                  </small>}
                </div>

                <div className="col-md-4">
                  <p className={`mb-1 ${errors && errors.vimeo && 'text-danger'}`}><small><i className="icon-edit"></i>
                    Link do vimeo <span class="badge badge-warning"> Pamiętaj o słowie player w linku! </span></small></p>
                  <textarea
                    className={`w - 100 borders form-control ${errors && errors.vimeo && 'is-invalid'}`}
                    id="vimeo"
                    name="vimeo"
                    type="text"
                    placeholder='Podaj link'
                    value={vimeo}
                    onChange={onVimeoChange}
                  />
                  {errors && errors.vimeo && <small className="text-danger">
                    {errors && errors.vimeo}
                  </small>}
                </div>
              </div>

              <div className="com-md-12"><hr /></div>

              {/*   images_start   */}

              <div className="row pt-2">
                <div className={`col-md-4 pt-2 ${errors && errors.cover && 'text-danger'}`}><i className="icon-check"></i> Główna
                  grafika <br/>
                  <Dropzone className={'error'} onDrop={onDrop1} accept={"image/png,image/jpg,image/jpeg"}
                    multiple={false} error={errors && errors.cover ? true : false}/>
                  <ImageList onDelete={onDelete1} images={cover}/>
                  {errors && errors.cover && <small className="text-danger">
                    {errors && errors.cover}
                  </small>}
                </div>

                <div className={`col-md-4 pt-2 ${errors && errors.images && 'text-danger'}`}><i
                  className="icon-check"></i> Miniaturki <br/>
                  <Dropzone onDrop={onDrop2} accept={CONTENT_TYPES.join(',')}
                    error={errors && errors.images ? true : false}/>
                  <ImageList onDelete={onDelete2} images={images}/>
                  {errors && errors.images && <small className="text-danger">
                    {errors && errors.images}
                  </small>}
                </div>

                <div className={`col-md-4 pt-2 ${errors && errors.files && 'text-danger'}`}><i className="icon-check"></i> Pliki z
                  materiałami do pobrania <br/>
                  <Dropzone onDrop={onDrop3} accept={CONTENT_TYPES.join(',')+",application/pdf"}
                    error={errors && errors.files ? true : false}/>
                  <ImageList onDelete={onDelete3} images={files}/>
                  {errors && errors.files && <small className="text-danger">
                    {errors && errors.files}
                  </small>}
                </div>
              </div>

            </div>


            {/*   images_end   */}

            <div className="form-group pt-2">
              <div className="col text-center">
                <button type='submit' className='btn_1 rounded'>{tutorial.id > 0 ? 'Zaktualizuj' : 'Zapisz'}</button>
              </div>
            </div>

            <div className="row">
              <div className="col text-center">
                <a className='btn_cstm btn_cstm_25 btn_cstm-soft text-danger' href={backUrl}>Kliknij tutaj, jeśli nie
                  chcesz edytować tej sekcji</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

export default NewTutorial;
