import React, { useState } from 'react';
import Lobby from './Lobby';
import Room from "./Room";

const VideoChat = ({userName, ownerUserName, roomName, roomId, chatSid, userToken, goBackUrl, updateUrl}) => {
  const [roomPassword, setRoomPassword] = useState('');
  const [token, setToken] = useState(userToken);
  const [backUrl, setBackUrl] = useState(goBackUrl);

  const handleSubmit = async event => {
    event.preventDefault();

    let data = await fetch(`/pokoje/${roomId}/auth`, {
      method: 'POST',
      body: JSON.stringify({
        identity: userName,
        password: roomPassword
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json());

    setToken(data.token);
    setBackUrl(data.back_url);
  };

  const handleRoomPasswordChange = event => {
    setRoomPassword(event.target.value);
  };

  return token ? (
    <Room token={token}
          roomName={roomName}
          roomId={roomId}
          chatSid={chatSid}
          userName={userName}
          ownerUserName={ownerUserName}
          backUrl={backUrl}
          updateUrl={updateUrl}
    />
  ) : (
    <Lobby
      roomPassword={roomPassword}
      handleRoomPasswordChange={handleRoomPasswordChange}
      handleSubmit={handleSubmit}
    />
  )
};

export default VideoChat;
