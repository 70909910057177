import React from 'react';
import Linkify from "react-linkify";

const MessageItem = ({message}) => {
  return         <p>
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="blank" href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      {message.body}
    </Linkify>
  </p>
}

export default MessageItem
