import React from 'react';

const TextInput = ({ value, handleValueChange, label, id }) => {
  return (
    <div className="form-group form-focus">
      <input
        className="form-control floating"
        type="text"
        id={id}
        value={value}
        onChange={handleValueChange}
        required
      />
      <label className="focus-label">{label}</label>
    </div>
  );
}

export default TextInput;
