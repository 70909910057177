import axios from 'axios';


export function createTutorial(formData, user_profile_id, authenticityToken) {
  const API_URL = `/panel/${user_profile_id}/szkolenia-online`;

  return axios({
    method: 'post',
    url: API_URL,
    data: formData,
    headers: {
      "X-CSRF-Token": authenticityToken
    },
    config: { headers: {'Content-Type': 'multipart/form-data' }}
  });
}

export function updateTutorial(formData, tutorial_id, user_profile_id, authenticityToken) {
  const API_URL = `/panel/${user_profile_id}/szkolenia-online/${tutorial_id}`;

  return axios({
    method: 'patch',
    url: API_URL,
    data: formData,
    headers: {
      "X-CSRF-Token": authenticityToken
    },
    config: { headers: {'Content-Type': 'multipart/form-data' }}
  });
}
