import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Card extends Component {
  handleClick = () => {
    this.props.cardClicked(this.props.card);
  }

  cardClassName = () => {
    if (this.props.card.flip) {
      return "card flip"
    } else {
      return "card"
    }
  }

  cardFrontClassName = () => {
    if (this.props.card.off === true) {
      return "card__front off"
    } else {
      return "card__front"
    }
  }

  render() {
    return (
      <div className={this.cardClassName()} onClick={this.handleClick}>
        <div className="card__inner">
          <div className={this.cardFrontClassName()}></div>
          <div style={{ backgroundImage: 'url(' + this.props.card.url + ')',
                        backgroundSize: 'cover' }} className="card__back" />
        </div>
      </div>
    )
  }
}

Card.propTypes = {};

export default Card;
