import React, { Component } from "react";
import CardList from "./memory_game/CardList";
import "../packs/application.css";

class MemoryGame extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let cards = [];

    this.props.cards.forEach((card) => {
      cards.push({ id: card.id, flip: false, url: card.first_card });
      cards.push({ id: card.id, flip: false, url: card.first_card });
    });

    function shuffle(arr) {
      var i, j, temp;
      for (i = arr.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
      }
      return arr;
    }

    cards = shuffle(cards);

    this.setState({ cards });
  }

  flipCards = (cards) => {
    this.setState({ cards });
  };

  setgrid = () => {
    let cardsNumber = this.props.cards.length;
    let grid = 2;

    if (cardsNumber % 5 === 0) {
      grid = 5;
    } else if (cardsNumber % 4 === 0) {
      grid = 4;
    } else if (cardsNumber % 3 === 0) {
      grid = 3;
    }

    // return grid;
    return 4
  };

  render() {
    return (
      <div className={`MemoryGame cards-${this.setgrid()}`}>
        <CardList flipCards={this.flipCards} cards={this.state.cards} />
      </div>
    );
  }
}

export default MemoryGame;
