import React from 'react';
import TextInput from "./elements/TextInput";
import loginImg from '../../../assets/images/themes/konsultacje-online.jpg';

const Lobby = ({
                 roomPassword,
                 handleRoomPasswordChange,
                 handleSubmit
               }) => {
  return (
    <section className="pt-5 pb-5">
      <div className="row align-items-center justify-content-center login-box">
        <div className="col-md-7 col-lg-6 login__img">
          <img src={loginImg} alt="login image" className="img-fluid"/>
        </div>
        <div className="col-md-12 col-lg-6 login__form mb-5">
          <h2 className="h5 font-weight-bold pb-2">Dołącz do spotkania online</h2>

          <form onSubmit={handleSubmit}>
            <TextInput label="Wprowadź swój klucz"
                       value={roomPassword}
                       handleValueChange={handleRoomPasswordChange}
                       id="room"/>
            <div className="text-right mb-2">
              <a href="#" data-toggle="modal" data-target="#lost_pin" className="btn-link">Nie znasz Klucza?</a>
            </div>
            <button className="btn btn-t-primary btn-block btn-lg login-btn" type="submit">Dołącz</button>
            <div className="text-center dont-have mt-4">Nie wiesz od czego zacząć?
              <a href="#" data-toggle="modal" data-target="#instruction" className="btn-link">Instrukcja</a>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <p className="pt-3">
            Jesteś specjalistą i chcesz prowadzić z konsultacje online,
            <span className="text-primary">	napisz na kontakt@logopeda.pl</span>
          </p>
        </div>
        <div className="col-md-6"></div>
      </div>
    </section>
  );
};

export default Lobby;
