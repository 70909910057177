import React from 'react';
import PropTypes from 'prop-types';

const ChapterInputs = ({ idx, chapterState, handleChapterChange, removeChapter }) => {
  const chapterId = `name-${idx}`;
  const descriptionId = `description-${idx}`;
  const priorityId = `priority-${idx}`;
  const promo_videoId = `promo_video-${idx}`;
  const vimeoId = `vimeo-${idx}`;
  const youtubeId = `youtube-${idx}`;
  return (
    <div key={`chapter-${idx}`}>

      <div className="col-12" >
        <hr />
      </div>
      <div className="row pt-2">
        <div className="col-md-2">
          <p className="m-1"><small><i className="icon-edit"></i> Rozdział </small></p>
          <input
            className="w-100 borders form-control"
            id={priorityId}
            name='priority'
            data-idx={idx}
            type="number"
            placeholder='Numer rozdziału'
            value={chapterState[idx].priority}
            onChange={handleChapterChange}
          />
        </div>

        <div className="col-md-10">
          <p className="m-1"><small><i className="icon-edit"></i> Tytuł rozdziału </small></p>
          <input
            className="w-100 borders form-control"
            id={chapterId}
            name='name'
            data-idx={idx}
            type="text"
            placeholder='Tytuł rozdziału'
            value={chapterState[idx].name}
            onChange={handleChapterChange}
          />
        </div>
      </div>

      <div className="row pt-2">
        <div className="col-md-12">
          <p className="m-1"><small><i className="icon-edit"></i> Krótki opis </small></p>
          <input
            className="w-100 borders form-control"
            type="text"
            name='description'
            data-idx={idx}
            id={descriptionId}
            placeholder="Krótki opis"
            value={chapterState[idx].description}
            onChange={handleChapterChange}
          />
        </div>
        <div className="col-md-4">
          <p className="m-1"><small><i className="icon-edit"></i> Link do filmu promocyjnego </small></p>
          <input
            className="w-100 borders form-control"
            name='promo_video'
            data-idx={idx}
            id={promo_videoId}
            placeholder='Podaj link'
            value={chapterState[idx].promo_video}
            onChange={handleChapterChange}
          />
        </div>
        <div className="col-md-4">
          <p className="m-1"><small><i className="icon-edit"></i> Embed code youtube </small></p>
          <input
            className="w-100 borders form-control"
            type="text"
            name='youtube'
            data-idx={idx}
            id={youtubeId}
            placeholder='Podaj link'
            value={chapterState[idx].youtube}
            onChange={handleChapterChange}
          />
        </div>
        <div className="col-md-4">
          <p className="m-1"><small><i className="icon-edit"></i> Embed code vimeo </small></p>
          <input
            className="w-100 borders form-control"
            type="text"
            name='vimeo'
            data-idx={idx}
            id={vimeoId}
            placeholder='Podaj link'
            value={chapterState[idx].vimeo}
            onChange={handleChapterChange}
          />
        </div>
      </div>
      {chapterState.length > 1 && <div className="form-group pb-2">
        <button
          type="button"
          value="Dodaj rozdział"
          className="btn_3 rounded float-right"
          onClick={() => removeChapter(chapterState[idx])} >
          Usuń
        </button>
      </div>}

    </div>
  );
};

ChapterInputs.propTypes = {
  idx: PropTypes.number,
  chapterState: PropTypes.array,
  handleChapterChange: PropTypes.func,
};

export default ChapterInputs;
